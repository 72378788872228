@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme: #7746dc;
  --theme-dark: #331b65;
}
html {
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: var(--theme-dark);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--theme);
  border-left: 2px solid var(--theme-dark);
  border-right: 2px solid var(--theme-dark);
}

@layer components {
  .container {
    @apply max-w-[80rem] !w-full mx-auto px-4 text-white !important;
  }
  .input {
    @apply bg-theme-black px-4 py-3 rounded-lg border-white/10 border-2 focus:border-theme-purple outline-none transition duration-300;
  }
}

.link {
  position: relative;
}

.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: var(--theme);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
}

.link:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}
.circle {
  background: var(--theme);
  background: -o-linear-gradient(
    190deg,
    var(--theme) 0%,
    rgba(115, 67, 210, 0) 100%
  );
  background: linear-gradient(
    260deg,
    var(--theme) 0%,
    rgba(115, 67, 210, 0) 100%
  );
  -webkit-filter: blur(150px);
  filter: blur(150px);
}
